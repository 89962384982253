<template>
  <multiselect :id="id" :name="name" :options="options" :searchable="true" 
               @input="$emit('input', 
  $event)" 
               :allow-empty="false"
               :placeholder="placeholder || 'Wpisz i wyszukaj lotnisko'" @open="$emit('open')" @close="$emit('close')" :max-height="maxHeight"
               :show-labels="false" label="text" track-by="name" v-model="internalValue" :loading="isLoading" 
               @search-change="asyncFind">
    <template slot="singleLabel"
              slot-scope="{ option }"><span v-bind:id="option.value">{{ option.text
      }}</span></template>
    <template slot="noResult"><slot>Brak wyników</slot></template>
    <template slot="noOptions"><slot>Brak wyników</slot></template>
    <template slot="option" 
              slot-scope="{ option }"><span v-bind:id="option.value">{{ option.text }}</span></template>
   
  </multiselect>
</template>

<script>
import Multiselect from "vue-multiselect";
import debounce from 'lodash/debounce';

export default {
  model: {
    prop: 'internalValue',
    event: 'input'
  },
  props: ['value', 'placeholder', 'maxHeight', 'defaultValue', 'name', 'id'],
  data() {
    return {
      options: [],
      isLoading: false,
      internalValue: this.value,
    }
  },
  methods: {
    asyncFind: debounce(async function (term) {
      if (!(term || '').length) {
        return;
      }

      this.isLoading = true;
      this.options = (await this.$store.dispatch('airports/getAirports', {term}))
          .map(airport => ({ value: airport.id, text: airport.name }));
      this.isLoading = false;
    }, 250)
  },
  watch: {
    defaultValue: function (val) {
      if (!val || this.options.length || this.internalValue) {
        return;
      }

      this.internalValue = val;
      this.options.push(val);
    }
  },
  components: {Multiselect},
  name: "airport-picker"
}
</script>

<style scoped>

</style>
