const MAX_AGE = 22;

export default {
    props: {
        makes: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            carMakes: [],
            years: [],
            selects: {},
        }
    },
    methods: {
        generateYears() {
            const currentYear = (new Date()).getFullYear();

            this.years = [...Array(MAX_AGE).keys()].map(i => currentYear - i);
        },
        onSelectChange(value, targetObject, property) {
            this.$set(targetObject || this, property, value
                ? (typeof value === 'object' ? value.value : value)
                : undefined);
        },
        mapMakes() {
            this.carMakes.push({
                label: 'Popularne marki',
                items: this.makes.popular_cars.map(make => ({ value: make, text: make })),
            }, {
                label: 'Pozostałe marki',
                items: this.makes.other_cars.map(make => ({ value: make, text: make })),
            });
        },
    }
}