var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "multiselect",
    {
      attrs: {
        id: _vm.id,
        name: _vm.name,
        options: _vm.options,
        searchable: true,
        "allow-empty": false,
        placeholder: _vm.placeholder || "Wpisz i wyszukaj lotnisko",
        "max-height": _vm.maxHeight,
        "show-labels": false,
        label: "text",
        "track-by": "name",
        loading: _vm.isLoading,
      },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event)
        },
        open: function ($event) {
          return _vm.$emit("open")
        },
        close: function ($event) {
          return _vm.$emit("close")
        },
        "search-change": _vm.asyncFind,
      },
      scopedSlots: _vm._u([
        {
          key: "singleLabel",
          fn: function ({ option }) {
            return [
              _c("span", { attrs: { id: option.value } }, [
                _vm._v(_vm._s(option.text)),
              ]),
            ]
          },
        },
        {
          key: "option",
          fn: function ({ option }) {
            return [
              _c("span", { attrs: { id: option.value } }, [
                _vm._v(_vm._s(option.text)),
              ]),
            ]
          },
        },
      ]),
      model: {
        value: _vm.internalValue,
        callback: function ($$v) {
          _vm.internalValue = $$v
        },
        expression: "internalValue",
      },
    },
    [
      _vm._v(" "),
      _c(
        "template",
        { slot: "noResult" },
        [
          _vm._t("default", function () {
            return [_vm._v("Brak wyników")]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "noOptions" },
        [
          _vm._t("default", function () {
            return [_vm._v("Brak wyników")]
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }