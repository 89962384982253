import axios from 'axios';

const actions = {
    async getAirports(context, { term }) {
        const { data } = await axios.get('/wp-content/themes/skyhelp/communication/airports.php', {
            params: {term},
        });

        return data;
    },
    async getAirlines(context, { term }) {
        const { data } = await axios.get('/wp-content/themes/skyhelp/communication/airlines.php', {
            params: {term},
        });

        return data;
    }
};

export default {
    namespaced: true,
    actions,
};