import axios from 'axios';

const generateStopoverEntry = (source, destination) => {
    return {
        value: source.text,
        source: source.text,
        sourceId: source.value,
        target: destination.text,
        targetId: destination.value,
    }
}

const state = () => ({
    startAirport: undefined,
    destinationAirport: undefined,
    stopovers: [],
    problematicAirport: undefined,
    problematicAirline: undefined,
    delayHours: undefined,
    problem: undefined,
    compensationAmount: undefined,
    reservationNumber: undefined,
});

const getters = {
    stopovers(state) {
        return state.stopovers;
    },
    startAirport(state) {
        return state.startAirport;
    },
    destinationAirport(state) {
        return state.destinationAirport;
    },
    problematicAirport(state) {
        return state.problematicAirport;
    },
    problematicAirline(state) {
        return state.problematicAirline;
    },
    problematicAirports(state) {
        if (!state.startAirport || !state.destinationAirport) {
            return [];
        }

        if (!state.stopovers.length || state.stopovers.some(s => typeof s.airport === 'undefined')) {
            return [
                generateStopoverEntry(state.startAirport, state.destinationAirport),
            ];
        }

        const result = [
            generateStopoverEntry(state.startAirport, state.stopovers[0].airport),
        ];

        const stopoversCount = state.stopovers.length;

        if (stopoversCount > 1) {
            for (let i = 0; i < stopoversCount - 1; i++) {
                result.push(generateStopoverEntry(state.stopovers[i].airport, state.stopovers[i + 1].airport));
            }
        }

        result.push(generateStopoverEntry(state.stopovers[stopoversCount - 1].airport, state.destinationAirport));
        return result;
    },
    delayedHours(state) {
        return state.delayHours;
    },
    problem(state) {
        return state.problem;
    },
    compensationAmount(state) {
        return state.compensationAmount;
    },
    reservationNumber(state) {
        return state.reservationNumber;
    }
};

const mutations = {
    setStopovers(state, { stopovers }) {
        state.stopovers = stopovers;
    },
    setStartAirport(state, { airport }) {
        state.startAirport = airport;
    },
    setDestinationAirport(state, { airport }) {
        state.destinationAirport = airport;
    },
    setProblematicAirport(state, { airport }) {
        state.problematicAirport = airport;
    },
    setProblematicAirline(state, { airline }) {
        state.problematicAirline = airline;
    },
    setDelayedHours(state, { hours }) {
        state.delayHours = hours;
    },
    setProblem(state, { problem }) {
        state.problem = problem;
    },
    setCompensationAmount(state, { amount }) {
        state.compensationAmount = amount;
    },
    setReservationNumber(state, { reservationNumber }) {
        state.reservationNumber = reservationNumber;
    }
};

const actions = {
    async calculatePayout(context) {
        const data = new FormData();

        data.append('reason', context.getters.problem);
        data.append('delay_flight_hours', context.getters.delayedHours);

        context.getters.problematicAirports.forEach((airport, index) => {
            data.append(`travel[${index}][departure_airport_id]`, airport.sourceId);
            data.append(`travel[${index}][arrival_airport_id]`, airport.targetId);
        });

        const { data: responseData } = await axios.post('/wp-content/themes/skyhelp/communication/calculation.php', data);

        context.commit('setCompensationAmount', {
            amount: responseData.amount_compensation
        });

        return responseData.data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};