import Vue from 'vue';
import Vuex, {createLogger} from 'vuex';
import airports from "./modules/airports";
import form from "./modules/form";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        airports,
        form
    },
    strict: debug,
    plugins: debug ? [createLogger()] : [],
})