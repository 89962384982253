<template>
  <div class="form-wrapper mb-5" :class="{ 'is-valid': !$v.$invalid }">
    <form id="header-form" method="post" action="/formularz" class="form-box narrow managable" @submit="onSubmit">
      <div class="custom-select-wrapper" tabindex="1">
        <div class="custom-select form-group">
          <div class="custom-select-trigger">
            <div class="icon-wrapper">
              <div class="icon-wrapper">
                <img src="/wp-content/themes/skyhelp/assets/img/icons/miejsce-wylotu.svg" alt="miejsce wylotu ikona">
              </div>
            </div>
            <airport-picker placeholder="Miejsce wylotu" name="departure-airport" id="departureAirport" 
                            v-model="departureAirport">
              Wpisz i wyszukaj lotnisko
            </airport-picker>
          </div>
        </div>
      </div>
      <div class="form-group mb-0">
        <div class="custom-input-wrapper">
          <div class="custom-input">
            <div class="icon-wrapper">
              <img src="/wp-content/themes/skyhelp/assets/img/icons/lotnisko-docelowe.svg" alt="lotnisko docelowe ikona">
            </div>
            <airport-picker placeholder="Lotnisko docelowe" name="arrival-airport" id="arrivalAirport" 
                            v-model="arrivalAirport">
              Wpisz i wyszukaj lotnisko
            </airport-picker>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <button type="submit" class="btn btn-secondary">
          <div class="content">
            <div class="text">
              Sprawdź odszkodowanie
            </div>
          </div>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formFieldsMixin from '../../mixins/form-fields';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import AirportPicker from '../elements/airport-picker';

export default {
  data() {
    return {
      departureAirport: undefined,
      arrivalAirport: undefined,
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      localStorage.setItem('departure_airport', encodeURIComponent(JSON.stringify(this.departureAirport)));
      localStorage.setItem('arrival_airport', encodeURIComponent(JSON.stringify(this.arrivalAirport)));

      event.target.submit();
    }
  },
  validations: {
    departureAirport: {
      required,
    },
    arrivalAirport: {
      required
    }
  },
  mixins: [formFieldsMixin, validationMixin],
  components: {AirportPicker},
  name: "form-box"
}
</script>

<style scoped lang="scss">
@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import '~bootstrap/scss/functions';
@import '../../../scss/variables';
@import '../../../scss/mixins';

.form-wrapper {
  &.is-valid {
    .form-box {
      .custom-input, .custom-select-trigger {
        border-color: $lime;
      }
    }
  }
}

::v-deep {
  .multiselect {
    position: initial;

    .multiselect__content-wrapper {
      border-color: $redish;
      margin-top: 10px;
      right: 0;
    }

    .multiselect__tags {
      border: 0;
      padding: 8px 25px 0 8px;
    }

    .multiselect__placeholder {
      font-size: 14px;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    .multiselect__select {
      width: 35px;
    }

    .multiselect__input {
      margin-bottom: 8px;
      padding: 2px 0 0 0;
      font-size: 14px;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    .multiselect__single {
      margin-bottom: 6px;
      padding-top: 2px;
    }

    .multiselect__option--disabled {
      padding-left: 15px !important;
      color: #3B3C42 !important;
      background: #fff !important;
      font-weight: 500;
    }

    .multiselect__option {
      min-height: 37px;
      padding: 10px 15px 10px 23px;
      border-bottom: 1px solid #F1F1F1 !important;
    }

    .multiselect__option--highlight {
      background: #e8ecf8;
      color: #333;
    }

    .multiselect__element {
      margin-bottom: 0;
    }

    .multiselect-option {
      &.is-disabled {
        cursor: pointer;
        text-decoration: none;
      }
    }

    .multiselect__select {
      height: 100%;

      &:before {
        line-height: 1;
        border: 0;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 12px;
        content: "\f078";
        top: 50%;
        left: 0;
        position: absolute;
        transform: translateY(-50%);
        margin: 0;
      }
    }
  }
}
</style>